import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const animationTiming = 15000

const getRowAndColumn = function(row, column) {
  column = column || 'A'
  row = row || '1'
  const rawcolumnnumber = column.charCodeAt(0)
  let rownumber
  let columnnumber
  let overallNumber
  if (column.length > 1) {
    columnnumber = rawcolumnnumber - 64 + 26
  } else {
    columnnumber = rawcolumnnumber - 64
  }
  rownumber = parseInt(row, 10)
  overallNumber = (rownumber - 1) * 29 + columnnumber
  return { r: rownumber, c: columnnumber, o: overallNumber }
}

const compare = function(a, b) {
  if (a.node.location.o < b.node.location.o) {
    return -1
  }
  if (a.node.location.o > b.node.location.o) {
    return 1
  }
  return 0
}

const useKeyPress = function(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
  })

  return keyPressed
}

const QuiltBlock = ({ quiltBlock, active, setSelected }) => (
  <div
    className={`story--legacy ${active ? 'active' : ''} row-start-${
      quiltBlock.location.r
    } col-start-${quiltBlock.location.c}`}
    onClick={() => setSelected(quiltBlock)}
  >
  </div>
)

const Selected = ({ selected }) => {
  const [changing, setChanging] = useState(false)

  useEffect(() => {
    setChanging(false)
    const timer = setTimeout(() => {
      // console.log("This will run after 1 second!");
      setChanging(true)
    }, 100)
    return () => clearTimeout(timer)
  }, [selected])

  return (
    <div className="selected">
      <div
        className={`selected__inner ${changing ? 'selected__inner_changing' : ''}`}
      >
        {selected.quiltBlockFields.quiltImage && (
          <GatsbyImage 
            loading="eager" 
            className="absolute" 
            image={selected.quiltBlockFields.quiltImage.gatsbyImage} 
            alt={selected.quiltBlockFields.quiltImage.altText} 
          />
        )}
        {selected.title !== "Empty" && (
          <h1
            dangerouslySetInnerHTML={{ __html: selected.title}}
          />
        )}
        <p className="text-gray-600 mb-5">
          {selected.quiltBlockFields.city && <span>{selected.quiltBlockFields.city}</span>}
          {selected.quiltBlockFields.state && selected.quiltBlockFields.city && <span>, </span>}
          {selected.quiltBlockFields.state && <span>{selected.quiltBlockFields.state}</span>}
          {selected.quiltBlockFields.state || selected.quiltBlockFields.city ? <span> | </span> : ''}
          {selected.quiltBlockFields.date ? (
            <span> {selected.quiltBlockFields.date}</span>
          ) : (
            <span> {selected.quiltBlockFields.century}</span>
          )}
        </p>
        <h4
          dangerouslySetInnerHTML={{ __html: selected.quiltBlockFields.description }}
        />
        {selected.quiltBlockFields.donor && (
          <p className="mt-5">
            <span className="font-bold">Adopted By</span>{' '}
            {selected.quiltBlockFields.donor}
          </p>
        )}
      </div>
    </div>
  )
}

const LegacyQuilt = ({ data }) => {
  const quilt = data.allWpQuiltBlock.edges
  quilt.map(quiltBlock => {
    quiltBlock.node.location = getRowAndColumn(
      quiltBlock.node.quiltBlockFields.locationRow,
      quiltBlock.node.quiltBlockFields.locationColumn
    )
  })
  quilt.sort(compare)
  const [selected, setSelected] = useState(quilt[0].node)
  const downPress = useKeyPress('ArrowDown')
  const upPress = useKeyPress('ArrowUp')
  const leftPress = useKeyPress('ArrowLeft')
  const rightPress = useKeyPress('ArrowRight')
  const enterPress = useKeyPress('Enter')
  const [cursor, setCursor] = useState(0)
  const [idle, setIdle] = useState(true)

  useEffect(() => {
    const startAdvancing = setInterval(() => {
      setIdle(true)
    }, 5000)
    
    return () => {
        clearInterval(startAdvancing)
    }
  })

  useEffect(() => {
    const advanceCursor = setInterval(() => {
      if (idle) {
      setCursor(prevState => (prevState + 1))
      setSelected(quilt[cursor + 1].node)
      }
    }, animationTiming)
    
    return () => {
        clearInterval(advanceCursor)
    }
  })

  useEffect(() => {
    if (quilt.length && downPress) {
      setCursor(prevState =>
        prevState < quilt.length - 29 ? prevState + 29 : prevState
      )
    }
  }, [downPress])
  useEffect(() => {
    if (quilt.length && upPress) {
      setCursor(prevState => (prevState > 29 ? prevState - 29 : prevState))
    }
  }, [upPress])
  useEffect(() => {
    if (quilt.length && rightPress) {
      setCursor(prevState =>
        prevState < quilt.length - 1 ? prevState + 1 : prevState
      )
    }
  }, [rightPress])
  useEffect(() => {
    if (quilt.length && leftPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState))
    }
  }, [leftPress])
  useEffect(() => {
    if (quilt.length && enterPress) {
      setSelected(quilt[cursor].node)
    }
  }, [cursor, enterPress])

  useEffect(() => {
    setIdle(false)
  }, [upPress, downPress, rightPress, leftPress, enterPress])

  return (
    <div className="legacy__container">
      <div className="legacy__quilt">
        {quilt.map(({ node: quiltBlock }, i) => (
          <QuiltBlock
            key={quiltBlock.id}
            active={i === cursor}
            quiltBlock={quiltBlock}
            setSelected={setSelected}
          />
        ))}
      </div>
      <div className="legacy__companion">
        <Selected selected={selected} />
      </div>
    </div>
  )
}

export const query = graphql`
  {
    allWpQuiltBlock(filter: { quiltBlockFields: { legacy: { eq: true } } }) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          tags {
            nodes {
              name
              slug
            }
          }
          quiltBlockFields {
            century
            date
            city
            state
            description
            descriptionLong
            donor
            gender
            locationColumn
            locationRow
            legacy
            quiltImage {
              gatsbyImage(layout: CONSTRAINED, width: 1200, height: 1200)
              altText
            }
          }
        }
      }
    }
  }
`

export default LegacyQuilt
